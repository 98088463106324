<template>
  <form class="form" @submit.prevent="handlePasswordChange">
    <BaseInputPassword
      v-model="user.old_password"
      label="Mot de passe actuel"
      placeholder="Entrez le mot de passe actuel"
      :isInvalid="
        $v.user.old_password.$error || errors.old_password !== undefined
      "
      @blur="$v.user.old_password.$touch()"
    >
      <template v-if="$v.user.old_password.$error || errors.old_password">
        <label class="small form-text text-danger text-right">
          <em v-if="!$v.user.password.required"
            >le mot de passe actuel est requis</em
          >
          <em v-if="errors.old_password">{{ errors.old_password[0] }}</em>
        </label>
      </template>
    </BaseInputPassword>
    <BaseInputPassword
      v-model="user.password"
      label="Nouveau mot de passe"
      placeholder="Entrez le nouveau mot de passe"
      :isInvalid="$v.user.password.$error || errors.password !== undefined"
      @blur="$v.user.password.$touch()"
    >
      <template v-if="$v.user.password.$error || errors.password">
        <label class="small form-text text-danger text-right">
          <em v-if="!$v.user.password.required"
            >le nouveau mot de passe est requis</em
          >
          <em v-if="!$v.user.password.minLength">au moins 6 caractères</em>
          <em v-if="!$v.user.password.containsUppercase">
            au moins une lettre en majuscule</em
          >
          <em v-if="!$v.user.password.containsNumber"> au moins un chiffre</em>
          <em v-if="errors.password">{{ errors.password[0] }}</em>
        </label>
      </template>
    </BaseInputPassword>
    <BaseInputPassword
      v-model="user.password_confirmation"
      label="Confirmer"
      placeholder="Confirmation du mot de passe"
      :isInvalid="$v.user.password_confirmation.$error"
      @blur="$v.user.password_confirmation.$touch()"
    >
      <template v-if="$v.user.password_confirmation.$error">
        <label class="small form-text text-danger text-right">
          <em v-if="!$v.user.password_confirmation.required"
            >la confirmation du mot de passe est requise</em
          >
          <em v-if="!$v.user.password_confirmation.sameAsPassword"
            >La confirmation du mots de passe ne correspondent pas</em
          >
        </label>
      </template>
    </BaseInputPassword>
    <div class="form-group row">
      <div class="col-lg-8 offset-lg-4">
        <ul class="list-unstyled" v-if="$v.$anyError || success || error">
          <li class="text-danger" v-if="error">
            <i class="fa fa-times"></i> {{ error }}
          </li>
          <li class="text-danger" v-if="$v.$anyError">
            <i class="fa fa-times"></i> Veuillez remplir correctement les
            champs.
          </li>
          <li class="text-success" v-if="success">
            <i class="fa fa-check"></i> {{ success }}
          </li>
        </ul>
      </div>
    </div>
    <div class="form-group mb-0 text-center">
      <button
        role="button"
        type="submit"
        class="btn btn-secondary"
        :disabled="$v.$invalid || !editing"
      >
        <i class="fa fa-save" v-if="!loading"></i>
        Changer de mot de passe
        <span
          class="spinner-grow spinner-grow-sm m-l-5"
          role="status"
          v-if="loading"
        ></span>
      </button>
    </div>
  </form>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "FormPassword",

  watch: {
    user: {
      deep: true,
      handler() {
        this.editing = true;
      }
    }
  },

  data() {
    return {
      loading: false,
      editing: true,
      success: null,
      errors: [],
      error: null,
      user: {
        old_password: null,
        password: null,
        password_confirmation: null
      }
    };
  },

  validations: {
    user: {
      old_password: { required },
      password: {
        required,
        minLength: minLength(6),
        containsUppercase: function(value) {
          return /[A-Z]/.test(value) === true;
        },
        containsNumber: function(value) {
          return /[0-9]/.test(value) === true;
        }
      },
      password_confirmation: { required, sameAsPassword: sameAs("password") }
    }
  },

  methods: {
    handlePasswordChange() {
      this.$v.$touch();
      if (!this.$v.invalid && !this.loading) {
        this.loading = true;
        this.success = null;
        this.errors = [];
        this.error = null;
        this.$store
          .dispatch("auth/changeUserPassword", this.user)
          .then(() => {
            this.success =
              "Modification du mot de passe effectuée avec succès.";
            this.editing = false;
            this.loading = false;
          })
          .catch(err => {
            if (err.response.data.errors)
              this.errors = err.response.data.errors;
            if (err.response.data.message)
              this.error = err.response.data.message;
            this.loading = false;
          });
      }
    }
  }
};
</script>

<style scoped></style>
